import React, { useState, useEffect } from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
import axios from "axios";
import logo2 from "../images/success.png";
import logo3 from "../images/invalid.png";
import { useHistory } from "react-router-dom";
// import logo3 from '../images/invalid2.jpg';
// import logo3 from '../images/invalid3.png';
// import logo3 from '../images/invalid4.png';
// import  '../App.css';
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

require("dotenv").config();

const DeleteAccountPage = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { height, width } = useWindowDimensions();

  const handleDeleteAccount = async () => {
    // Call your UserService to delete the account
    try {
      // Assuming deleteUserAccount is a method in your UserService
      //   await UserService.deleteUserAccount(userName, password);
      // Redirect to login page or homepage after successful deletion
      history.push("/home");
    } catch (error) {
      // Handle errors (e.g., incorrect password, server error)
      console.error("Error deleting account:", error.message);
    }
  };

  return loading ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: width,
        height: height - 50,
      }}
    >
      {" "}
      <div>
        <ClipLoader color="#fff" loading={loading} css={override} size={100} />
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <form>
        <div style={{ marginTop: 10, marginLeft: 5 }}>
          <label style={{ marginLeft: 10, fontWeight: "bold", fontSize: 20 }}>
            Delete Account
          </label>
        </div>
        <div style={{ marginLeft: 30, marginTop: 20 }}>
          <div style={{ marginBottom: 10 }}>
            <label className="App-label">Username</label>
            <br />
            <input
              type="text"
              className="App-input"
              value={userName}
              onChange={(e) => {
                // console.log(e.target.value)
                // if (e.target.value.length < 6) setError("Invalid password");
                // else {
                //   if (e.target.value !== confirmPassword) {
                //     //console.log(e.target.value, confirmPassword)
                //     setError("passwords do not match.");
                //   } else {
                //     setError("");
                //   }
                // }
                setUserName(e.target.value);
              }}
            />
            <br />
          </div>
          <label className="App-label">Password</label> <br />
          <input
            type="password"
            className="App-input"
            value={password}
            onChange={(e) => {
              //console.log('event ', e.target.value)
              //console.log('confirmpassword', confirmPassword)
              //   if (password.length <= 6) {
              if (e.target.value.length <= 5) {
                setError("Invalid password");
              } else {
                setError("");
              }
              setPassword(e.target.value);
            }}
          />
          <div style={{ color: "#a32121", marginTop: 20, fontSize: 14 }}>
            {error}
          </div>
          {/* {check && ((password !== confirmPassword) || password.length<6) ?password.length<6?
        {/* {error && (<div>fdsfsf<div/>)} */}
          <div style={{ marginTop: 40 }}>
            {/* <input type="button" style={{ borderRadius: 0 }} className="App-button" value="Reset Password" onClick={async () => { */}
            <button
              //   className="button"
              className="btn btn-primary"
              onClick={async () => {
                // console.log('token ')
                // console.log('token ',token)
                //console.log('password ',password)
                setLoading(true);
                // const response = await fetch(
                //   `${process.env.REACT_APP_MASTERHOST}/api/web/deletebusiness`,
                //   // `http://localhost:3001/api/web/deletebusiness`,
                //   {
                //     // const response = await fetch(`http://localhost:3000/resetpasswordtoken`, {
                //     method: "POST",
                //     headers: {
                //       "Content-Type": "application/json",
                //     },
                //     // mode: 'cors',
                //     body: JSON.stringify({ userName, password }),
                //   }
                // )
                try {
                  const response = await axios.post(
                    `${process.env.REACT_APP_MASTERHOST}/api/web/deletebusiness`,
                    {
                      userName,
                      password,
                    }
                  );
                  setLoading(false);
                  history.push("/");
                } catch (e) {
                  setLoading(false);
                  // console.log("error :", e.message);
                  setError("Invalid username or password");
                  // console.log("222222222222222222222222222222222");
                }

                // .then((res) => res.text())
                // .then((json) => {
                //   const jsonObj = JSON.parse(json);
                //   console.log(json);
                //   setLoading(false);
                //   if (jsonObj.error) {
                //     console.log("errorrrrrr 1");
                //     setError(jsonObj.error);
                //   } else {
                //     history.push("/home");
                //     return json;
                //   }
                //   // console.log('111111111111111111111111111111111')
                //   // console.log("1111111111");
                //   // history.push('/thanks')
                // })
                // .catch((e) => {
                //   // console.log(e)
                //   console.log("error :", e);
                //   // console.log("222222222222222222222222222222222");
                //   setLoading(false);
                //   // setError("errorrr");
                //   // setError(e);
                //   // history.push('/send')
                // });

                //   console.log( window.location.search.substring(1))
              }}
              disabled={error || password.length === 0 ? true : false}
            >
              Delete Account
            </button>
          </div>
          {/* <p style={{color:'#fff',fontSize:20}}> {process.env.REACT_APP_osama}fsdfsdfsd</p> */}
        </div>
      </form>
    </div>
  );
};

export default DeleteAccountPage;
